.interactive-floor {
	.interactive-floor__svg {
		width: 100%;
	}
	// background-color: rgb(18, 28, 34);
	.interactive-floor__tooltip-container {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		.interactive-floor__tooltip-office {
			font-family: Montserrat;
			font-style: normal;
			font-weight: 600;
			font-size: 18px;
			line-height: 22px;
			text-align: center;
			color: #00A55E;
			padding: 0 15px 0px 0px;
			@media only screen and (max-width: 400px) {
				font-size: 16px !important;
				padding-right: 8px;
			}
		}
		.interactive-floor__tooltip-content {
			font-family: Montserrat;
			font-style: normal;
			font-size: 16px;
			line-height: 22px;
			color: #151515;
			@media only screen and (max-width: 400px) {
				font-size: 14px !important;
			}
			.interactive-floor__tooltip-content-header {
				font-weight: 600;
				white-space: nowrap;
				@media only screen and (max-width: 400px) {
					white-space: unset !important;
				}
				width: 100%;
				min-width: 100%;
			}
		}
	}

	.number {
		fill: rgba(0, 165, 94, 1);
		font-weight: 700;
		font-size: 30px;
		@media (max-width: 750px) {
			font-size: 35px !important;
		}
		@media (max-width: 560px) {
			font-size: 40px !important;
		}
		@media (max-width: 480px) {
			font-size: 40px !important;
		}
		@media (max-width: 420px) {
			font-size: 35px !important;
		}
	}
	.note {
		font-size: 25px;
		fill: rgba(0, 165, 94, 1);
		font-weight: 500;
		@media (max-width: 560px) {
			font-size: 32px !important;
		}
	}
}
