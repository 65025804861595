.handbook-tooltip {
	position: fixed;
	background-color: white;
	z-index: 1000;
	pointer-events: none;
	box-shadow: 2px 4px 10px rgba(10, 20, 30, 0.1);
	border-radius: 10px;
	padding: 20px 18px 20px 18px;
	@media only screen and (max-width: 400px) {
		padding: 10px 9px 10px 9px !important;
	}
	white-space: nowrap;
	transform: translateY(calc(-105% - 10px)) translateX(calc(-50%));
	max-width: 50vw;
	@media screen and (max-width: 750px) {
		white-space: unset !important;
		border-radius: 0px !important;
	}
	.handbook-tooltip__dialog-piece {
		content: "";
		position: absolute;
		width: 0;
		height: 0;
		border-width: 11px;
		border-style: solid;
		border-color: white transparent transparent transparent;
		bottom: 0px;
		left: 50%;
  	}
}
