.handbook-contacts {
    .handbook-contacts__container {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-gap: 51px 0;
        .handbook-contacts__item {
            .handbook-contacts__item-image {
                width: 100%;
            }
            .handbook-contacts__item-content {
                font-family: Montserrat;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 150%;
                color: #3B3B3C;

                padding: 0 24px 24px 24px;
                .handbook-contacts__item-name {
                    font-weight: bold;
                    margin: 20px auto;
                    
                }
                .handbook-contacts__item-post {
                    white-space: pre-wrap;
                }
            }
        }
    }
}

@media (max-width: 1280px) {
    .handbook-contacts__container {
        grid-template-columns: repeat(4, 1fr) !important;
        grid-gap: 35px 0 !important;
        .handbook-contacts__item {
            .handbook-contacts__item-image {
            }
            .handbook-contacts__item-content {
                .handbook-contacts__item-name {
                    margin: 15px auto !important;
                }
                .handbook-contacts__item-post {
                }
            }
        }
    }
}

@media (max-width: 960px) {
    .handbook-contacts__container {
        grid-template-columns: repeat(3, 1fr) !important;
        grid-gap: 20px 0 !important;
        .handbook-contacts__item {
            .handbook-contacts__item-image {
            }
            .handbook-contacts__item-content {
                .handbook-contacts__item-name {
                    margin: 12px auto !important;
                    
                }
                .handbook-contacts__item-post {
                }
            }
        }
    }
}

@media (max-width: 720px) {
    .handbook-contacts__container {
        grid-template-columns: repeat(2, 1fr) !important;
        .handbook-contacts__item {
            .handbook-contacts__item-image {
            }
            .handbook-contacts__item-content {
                .handbook-contacts__item-name {
                    margin: 16px auto !important;
                }
                .handbook-contacts__item-post {
                }
            }
        }
    }
}

@media (max-width: 480px) {
    .handbook-contacts__container {
        grid-template-columns: repeat(1, 1fr) !important;
        grid-gap: 51px 0 !important;
        .handbook-contacts__item {
            .handbook-contacts__item-image {
            }
            .handbook-contacts__item-content {
                .handbook-contacts__item-name {
                    margin: 20px auto !important;   
                }
                .handbook-contacts__item-post {
                }
            }
        }
    }
}