.handbook-offices {
	padding-top: 10px;
	padding-bottom: 40px;
	background-color: #141F25 !important;
	.handbook-offices__wrapper-wide {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		.handbook-offices__selected-floor {
			flex-basis: 100%;
			.handbook-offices__selected-floor-title {
				font-family: Montserrat;
				font-style: normal;
				font-weight: 600;
				font-size: 24px;
				line-height: 29px;
				color: #FFFFFF;
			}
			.handbook-offices__selected-floor-content-wrapper {
				display: flex;
				flex-direction: column;
				height: 100%;
				justify-content: center;
				margin-top: 20px;
				.handbook-offices__selected-floor-content {
					max-height: 60vh !important;
					max-width: 60vw !important;
					@media (max-width: 1280px) {
						max-width: 70vw !important;
					}
					@media (max-width: 960px) {
						max-width: 80vw !important;
					}
					@media (max-width: 750px) {
						max-width: 90vw !important;
					}
					@media (max-width: 480px) {
						max-width: 100vw !important;
					}
				}
			}
		}
		.handbook-offices__selector-section {
			flex-basis: 304px;
			flex-shrink: 0;
			// margin-left: 244px;
			margin-left: 150px;
			@media (max-width: 1280px) {
				flex-basis: 250px !important;
				margin-left: 100px !important;
			}
			@media (max-width: 960px) {
				flex-basis: 200px !important;
				margin-left: 50px !important;
			}
			.handbook-offices__selector-section-title {
				font-family: Montserrat;
				font-style: normal;
				font-weight: 600;
				font-size: 20px;
				line-height: 24px;
				color: #FFFFFF;

				text-align: center;
			}
			.handbook-offices__floor-list {
				margin-top: 26px;
				background-color: rgba(7, 18, 24, 1);
				padding: 13px 0;
				.handbook-offices__floor-item {
					margin: 20px;
					box-sizing: border-box;
					border: 2px solid transparent;
					&:not(.handbook-offices__floor-item_selected) {
						cursor: pointer;
					}
					&.handbook-offices__floor-item_selected, &:hover {
						background-color: rgba(20, 31, 37, 1);
						border: 2px solid #00A55E;
					}
					.handbook-offices__floor-item-title {
						font-family: Montserrat;
						font-style: normal;
						font-weight: normal;
						font-size: 16px;
						line-height: 20px;
						color: #FFFFFF;

						text-align: center;
						padding: 20px;
					}
					.handbook-offices__floor-item-content {
						padding: 0 38px 32px 38px;
					}
				}
			}
		}
	}
	.handbook-offices__wrapper-small {
		display: none;
	}
}


@media (max-width: 750px) {
	.handbook-offices {
		.handbook-offices__wrapper-wide {
			display: none !important;
		}
		.handbook-offices__wrapper-small {
			display: block;
			flex-direction: column;
			justify-content: space-between;
			.handbook-offices__floor-list {
				margin-top: 26px;
				// background-color: rgba(7, 18, 24, 1);
				padding: 13px 0;
				.handbook-offices__floor-item {
					height: 100%;
					margin: 0px;
					.handbook-offices__floor-item-title {
						font-family: Montserrat;
						font-style: normal;
						font-weight: normal;
						font-size: 20px;
						line-height: 20px;
						color: #FFFFFF;

						text-align: center;
						padding: 30px;
					}
					.handbook-offices__floor-item-content {
						padding-bottom: 32px;
						min-height: 100%;
					}
				}
			}		
		}
	}
}

