.Home {
    text-align: center;
}

.Home .card-container {
    padding-left: 16px;
}

.Home .html.ui.attached.segment {
    border: none;
    padding-left: 2px;
    padding-right: 2px;
}
.Home .ui.header:first-child.divider {
    margin-top: 4rem;
}

.Home .ui.cards > .card.no-custom-shadow:not(.trainee), .ui.card,
.Home .ui.cards a.card.no-custom-shadow:hover {
    box-shadow: none;
}
.Home .ui.cards > .card > :only-child, .ui.card > :only-child {
    border-radius: 0rem!important;
}
.wedding {
    width: 100%;
    max-width: 1250px;
    background-image: url(https://www.alanmalavoltilaw.com/wp-content/uploads/2018/06/wedding-greeting-cards-printable-print-greeting-cards-online-uk-free-printable-at-greetings-7-template-728x478.jpg);
    position: absolute;
    bottom: 0;
    height: 50%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
.Home .search-input{
    display: flex;
    flex: 1;
    height: 2.75rem;
}
.Home .padding-for-search-block {
    padding-top: 53px;
}
.Home .search-block {
    max-width: 785px;
    margin-left: 0px;
    width: 63%;
}
.Home .news-date {
   margin-bottom: 2rem;
    display: flex;
    justify-content: flex-end;
    margin-top: 5px;
}
.Home .news-text {
    text-align: left;
    white-space: pre-wrap;
}
.Home .ui.segment.user-block img{
    width: 100%;
    padding-top: 2rem;
    max-width: 600px;
}
.Home .ui.segment.user-block {
    position: fixed;
    height: 100%;
    max-height: calc(100% - 73px - 2rem);
    width: calc(62.5% - 2rem);
    max-width: calc(800px - 2rem);
}
.Home .ui.segment.home-info-block {
    position: fixed;
    width: calc(37.5% - 2rem);
    max-width: 450px;
    height: 100%;
    max-height: calc(100% - 73px - 2rem);
    overflow: auto;
}
.Home .ui.accordion {
    position: relative;
}
.Home .user-best-block {
    padding-left: 16px;
}
@media only screen and (max-width: 1355px) {
    .Home .search-block {
        width: calc(62.45% - 64px);
    }
}
@media only screen and (max-width: 1200px) {
    .Home .ui.six.cards > .card {
        width: calc( 19.9544% - 1.5em );
    }
}
@media only screen and (max-width: 1000px) {
    .Home .ui.six.cards > .card {
        width: calc( 24.9544% - 1.5em );
    }
}
@media only screen and (max-width: 850px) {
    .Home .ui.six.cards > .card {
        width: calc( 20% - 1.5em )
    }
    .Home .search-block {
        width: calc(100% - 94px);
        max-width: 958px;
    }
    .Home .ui.segment.home-info-block {
        width: auto;
        display: block;
        position: relative;
        flex-direction: column;
        justify-self: center;
    }
    .Home .ui.segment.user-block {
        width: calc(100% - 2rem);
        max-width: calc(100% - 2rem);
    }
}
@media only screen and (max-width: 650px) {
    .Home .ui.six.cards > .card {
        width: calc( 24.9544% - 1.5em );
    }
}
@media only screen and (max-width: 520px) {
    .Home .ui.six.cards > .card {
        width: calc( 33.333% - 1.5em );
    }
}
@media only screen and (max-width: 400px) {
    .Home .ui.six.cards > .card {
        width: calc( 50% - 1.5em );
    }
}
.zindex2000{
    z-index:2000;
}