/*.ProfileStatusesCard.ui.card{*/
    /*position: fixed;*/
/*}*/
/*.ProfileStatusesCard .description{*/
    /*text-align: left;*/
/*}*/
/*.ProfileStatusesCard .right {*/
    /*float: right;*/
/*}*/
/*.ProfileStatusesCard .green {*/
    /*color: green;*/
/*}*/
/*.ProfileStatusesCard .buttons .button.negative {*/
    /*flex: 0;*/
/*}*/
.ProfileRolesCard .ui.checkbox label,
.ProfileStatusesCard .ui.checkbox label {
    cursor: pointer;
}
