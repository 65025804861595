.AdminScheduleVacations {
    height: 100%;
    width: 100%;
    position: relative;
}
.AdminScheduleVacations .vacation-colored-block {
    flex: 1;
    padding: 1rem 0.5rem;
}
.AdminScheduleVacations .vacation-colored-block--unpaid {
  background-color: saddlebrown;
}
.AdminScheduleVacations .vacation-colored-block--paid {
  background-color: orange;
}
.AdminScheduleVacations .sick-colored-block {
    flex: 1;
    background-color: rgb(111, 142, 187);
    padding: 1rem 0.5rem;
    cursor: pointer;
}
.AdminScheduleVacations .ui.header .colored-container {
    display: flex;
    height: 100%;
}

.AdminScheduleVacations .flex-container {
    display: flex;
    width: 100%;
    background: #FFFFFF;
    margin: 1em 0em;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, .25);
}
.AdminScheduleVacations .flex-container .fixed-block {
    display: flex;
    flex-direction: column;
}
.AdminScheduleVacations .scrollable-block {
    display: flex;
    overflow: auto;
}
.AdminScheduleVacations .flex-container .ui.header {
    margin: 0;
    border-right: 1px solid rgba(34, 36, 38, 0.1);
    border-top: 1px solid rgba(34, 36, 38, 0.1);
    height: 64px;
}
.ui.styled.accordion .AdminScheduleVacations .flex-container .content {
    padding: 1rem;
}
.ui.styled.accordion .AdminScheduleVacations .flex-container .ui.header:first-child .content {
    display: flex;
    align-items: center;
    height: 100%;
}
.AdminScheduleVacations .flex-container .ui.header:first-child {
    border-top: 0;
    background: #F9FAFB;
    height: 85px;
}


.AdminScheduleVacations .text-center {
    text-align: center;
}
.AdminScheduleVacations .workday,
.AdminScheduleVacations .weekend {
    width: 100%;
    height: 100%;
}
.AdminScheduleVacations .flex-container .ui.header.weekend:first-child,
.AdminScheduleVacations .weekend {
    background: rgba(255, 0, 0, 0.13);
}
.AdminScheduleVacations .flex-container .ui.header.today:first-child,
.AdminScheduleVacations .today {
    background: rgb(175, 175, 175);
}
.AdminScheduleVacations .content-center {
    text-align: center;
}
.AdminScheduleVacations .content-center button.labeled{
    margin: 0 1rem;
}
.AdminScheduleVacations input {
    /*margin-top: 1em;*/
    -webkit-appearance: none;
    padding: 0.78571429em 1em;
    background: #FFFFFF;
    border: 1px solid rgba(34, 36, 38, 0.15);
    outline: none;
    color: rgba(0, 0, 0, 0.87);
    border-radius: 0.28571429rem;
    -webkit-box-shadow: 0em 0em 0em 0em transparent inset;
    box-shadow: 0em 0em 0em 0em transparent inset;
    -webkit-transition: color 0.1s ease, border-color 0.1s ease;
    -o-transition: color 0.1s ease, border-color 0.1s ease;
    transition: color 0.1s ease, border-color 0.1s ease;
    font-size: 1em;
    width: 100%;
    line-height: 1.2857;
}
