.AdminPhones {
    height: 100%;
    width: 100%;
    padding: 1rem;
}
.AdminPhones table tr a {
    color: #000;
}
.AdminPhones table .content input {
    border: 0;
}
.AdminPhones  .ui.checkbox {
    padding-top: 1rem;
}
.AdminPhones .description span.right {
    float: right;
}
