.Sick {

}
.Sick .color-example {
    margin-left: 1rem;
    width: 30px;
}
.Sick .padding-for-search-block {
    padding-top: 62px;
}
@media only screen and (max-width: 850px) {
    .Sick .ui.mobile-side-menu {
        flex-direction: column;
        display: block;
    }
    .Sick .mobile-side-menu .PageSidePanel {
        background: #FFF;
    }
    .Sick .SideColumnWithMobileBottomLine .ui.modal,
    .Sick .SideColumnWithMobileBottomLine .ui.modal .image.content {
        background: none;
    }
}
@media only screen and (max-width: 530px) {
    .Sick .for-admin.padding-for-search-block {
        padding-top: 105px;
    }
    .Sick .search-block {
        display: block;
        width: calc(100% - 80px);
    }
    .Sick .search-block .UsersSelector{
        padding: 0;
    }
    .Sick .search-block .search-input {
        width: 100%;
    }
    .Sick .search-block .for-admin.search-input{
        margin-top: 1rem;
    }
    .Sick .search-block .UsersSelector .ui.search.dropdown{
        width: 100%;
    }
}
