.Login {
  background-color: #f5f5f5;
  padding: 5% 1rem 0;
  display: flex;
  flex-direction: column;
  /*height: 100%;*/
  /* overflow: auto; */
}
.Login .ui.grid {
  overflow: auto;
}
.Login .ui .column {
  max-width: 450px;
}
.Login .ui.message:last-child {
  margin-bottom: 1em;
}
.Login .leftLabel {
  float: left;
}
.Login .rightLabel {
  float: right;
}
.Login .form .ui.segment {
  padding: 2rem;
}
.Login .form .ui.segment h2 {
  margin-bottom: 35px;
}
.Login .signup-link {
  margin-top: 1rem;
}
